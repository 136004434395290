import { Container } from "./styles";
import githubIcon from "../../assets/github.svg"
import DownloadApp from '../../assets/download.png'
import externalLink from "../../assets/external-link.svg"
import ScrollAnimation from "react-animate-on-scroll";


export function Project() {
  return (
    <Container id="project">
      <h2>My Projects</h2>
      <div className="projects">

      <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <svg width="50" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="#23ce6b " stroke-width="1" stroke-linecap="round" stroke-linejoin="round" ><title>Folder</title><path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path></svg>
              <div className="project-links">
                <a href="https://github.com/dhirajkumarrajput4/Smart-Contact-Manager" target="_blank" rel="noreferrer">
                  <img src={externalLink} alt="Visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>Smart-Contact-Manager</h3>
              <p>
              This project is a demonstration of a web application built with Spring Security, Hibernate, JPA, and
               JWT authentication. It showcases how to implement secure authentication and authorization mechanisms using 
               industry-standard technologies. Additionally, it includes a smart contact manager feature where users can create, delete,
                and update contact details including mobile number and image.
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                 <li>Spring Boot</li>
                 <li>Spring JPA</li>
                 <li>Spring Security</li>
                 <li>Thymeleaf with Bootstrap</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>      

        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <svg width="50" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="#23ce6b " stroke-width="1" stroke-linecap="round" stroke-linejoin="round" ><title>Folder</title><path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path></svg>
              <div className="project-links">
                <a href="https://github.com/dhirajkumarrajput4/library" target="_blank" rel="noreferrer">
                  <img src={externalLink} alt="Visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>Book Store Spring-Boot</h3>
              <p>
                Effortless Book Management: Add, remove, and set prices for your books effortlessly.
                Dynamic Pricing: Tailor prices to match the worth of each book in your collection.
                Real-time Updates: Stay informed with notifications on changes in your library.
                Secure Transactions: Trust in secure payment gateways for buying and selling.
                User-Friendly Interface: Navigate with ease, making book management a joy.
                Community Sharing: Connect with fellow readers, trade, and explore diverse bookshelves.
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>Spring Boot</li>
                <li>Spring JPA</li>
                <li>Bootstrap</li>
                <li>Thymeleaf</li>
              </ul>
              
            </footer>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <svg width="50" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="#23ce6b " stroke-width="1" stroke-linecap="round" stroke-linejoin="round" ><title>Folder</title><path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path></svg>
              <div className="project-links">
                <a href="https://github.com/dhirajkumarrajput4/E-commerce" target="_blank" rel="noreferrer">
                  <img src={externalLink} alt="Visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>E-commerce</h3>
              <p>
                Welcome to our eco-conscious E-commerce platform! Explore a carefully curated selection of sustainable products that resonate with your values. Our user-friendly interface empowers sellers to effortlessly showcase their eco-friendly offerings, while transparent supply chain details enable you to make informed choices. Join our community of conscious consumers and sellers, and embark on a journey of sustainable and mindful shopping. Welcome to eco-conscious E-commerce – where every purchase makes a positive impact on the planet!
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>Java</li>
                <li>Bootstrap</li>
                <li>Maven</li>
                <li>Appache Tomcate</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>

       

        {/* <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <svg width="50" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="#23ce6b " stroke-width="1" stroke-linecap="round" stroke-linejoin="round" ><title>Folder</title><path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path></svg>
              <div className="project-links">
                <a href="https://mitulindustries.com/" target="_blank" rel="noreferrer">
                  <img src={externalLink} alt="Visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>Mitul Industries - Manufacturing Industries Site</h3>
              <p>
              This manufacturing industry website shows off products and helps the business get more customers and information online. Making products is important but making money from them is the goal. This site can help the business make more profit.
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>Wordpress</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation> */}

      </div>
    </Container>
  );
}